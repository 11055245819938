<template>
  <div>
    Unauthorized
  </div>
</template>

<script>
export default {
  name: 'Unauthorized'
}
</script>
